import { initial } from './reducer';
import {
  IDataAlerts,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";

const selectAlerts = (state: IState): IDataAlerts => {
  const { alerts } = state;
  const _alerts = alerts.alerts ? alerts.alerts : initial.alerts;
  return { ..._alerts, list: _alerts.list?.filter(i => i.ts === 0) };
};

const selectCompleted = (state: IState): IDataAlerts => {
  const { alerts } = state;
  const _alerts = alerts.alerts ? alerts.alerts : initial.alerts;
  return { ..._alerts, list: _alerts.list?.filter(i => i.ts > 0) };
};

export const makeSelectAlerts = () => createSelector(selectAlerts, substate => substate);

export const makeSelectCompleted = () => createSelector(selectCompleted, substate => substate);

/* SELECTOR */

export {
  selectAlerts,
  selectCompleted,
  /* SELECTOR_EXPORT */
};
