import { initial } from './reducer';
import {
  IDataSymbols,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";

const selectSymbols = (state: IState): IDataSymbols => {
  const { symbols } = state;
  return symbols.symbols ? symbols.symbols : initial.symbols;
};

export const makeSelectSymbols = () =>
    createSelector(selectSymbols, substate => substate);

/* SELECTOR */

export {
  selectSymbols,
  /* SELECTOR_EXPORT */
};
