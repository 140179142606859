import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {tokenRequest} from '../request';
import {
  IAction,
  IDataToken,
  ITokenRequestDTO,
  IToken, TAsyncQuery1,
} from "../types";
import { selectTokens } from '../store/selectors';
import {checkHttpError} from "../helpers";
import jwtDecode from 'jwt-decode';

/* SAGA */

export function* token(_action: IAction<IDataToken>): SagaIterator {
  try {
    const tokens = yield select(selectTokens);
    const payload = yield call<TAsyncQuery1<ITokenRequestDTO, Partial<IDataToken>>>(tokenRequest.query, { apiKey: tokens.apiKey });
    const decoded: IToken = jwtDecode(payload.accessToken);
    payload.publicKey = decoded.publicKey;
    yield put(action(EActions.REQUEST_TOKEN_OK, payload));
  } catch (error: any) {
    yield put(action(EActions.REQUEST_TOKEN_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
