import { initial } from './reducer';
import {
  IDataLot,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";
import {ILotSymbol} from "../../types";
import {selectSymbols} from "../symbols";

const selectLot = (state: IState): IDataLot => {
  const { lot } = state;
  return lot.lots ? lot.lots : initial.lots;
};

const selectLots = (state: IState): ILotSymbol[] => {
  const _symbols = selectSymbols(state).list ?? [];
  const _lots = selectLot(state).lot;

  return _symbols.map(s => {
    const _lot = _lots?.ids?.find(l => l === s.id);

    return {
      ...s,
      id_parent: _lots?.id ?? 0,
      checked: !!_lot,
    };
  });
};

export const makeSelectLot = () => createSelector(selectLot, substate => substate);

export const makeSelectLots = () => createSelector(selectLots, substate => substate);

/* SELECTOR */

export {
  selectLot,
  selectLots,
  /* SELECTOR_EXPORT */
};
