import { EActions } from '../../actions';
import {IAction} from '../../types';
import { IStateToken, IDataToken } from './types';

export const initial: IStateToken = {
  tokens: {
    apiKey: 'aef1d20eebce504bd6271bd3cbc250d5410225c8e3b99f7a7df8cca8ad46f1fc',
    accessToken: undefined,
    refreshToken: undefined,
    publicKey: undefined,
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function tokenReducer(state = initial, action: IAction<Partial<IDataToken>>): IStateToken {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.REQUEST_TOKEN:
      return {
        tokens: {
          ...state.tokens,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_TOKEN_OK:
      return {
        tokens: {
          ...state.tokens,
          accessToken: action.payload?.accessToken,
          refreshToken: action.payload?.refreshToken,
          publicKey: action.payload?.publicKey,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_TOKEN_ERR:
      return {
        tokens: {
          ...state.tokens,
          accessToken: undefined,
          refreshToken: undefined,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
    default:
      return state;
  }
}
