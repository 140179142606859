import {call, put, race, select, take} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {createAlertRequest, removeAlertRequest} from '../request';
import {IAction, IAlertRequestDTO, IDataAlert, IDataAlertDTO, TAsyncQuery2} from "../types";
import {selectTokens} from '../store/selectors';
import {checkHttpError} from '../helpers';

/* SAGA */

export function* beforeNewAlert(_action: IAction<Partial<IDataAlert>>): SagaIterator {
  try {
    if (!_action.payload?.symbol?.lot) {
      yield put(action(EActions.REQUEST_LAST_PRICE, {id_symbol: _action.payload?.id_symbol}));
      const {error, success} = yield race({
        error: take(EActions.REQUEST_LAST_PRICE_ERR),
        success: take(EActions.REQUEST_LAST_PRICE_OK),
      });
      if (error) {
        throw new Error(error);
      }
      yield put(action(EActions.NEW_ALERT, {
        ..._action.payload,
        symbol: success.payload.symbol,
        val: success.payload.symbol.lastPrice,
      }));
    } else {
      yield put(action(EActions.NEW_ALERT, {
        ..._action.payload,
        val: 0,
      }));
    }
  } catch (error) {
    yield put(action(EActions.CREATE_ALERT_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}

export function* createAlert(_action: IAction<IDataAlertDTO>): SagaIterator {
  try {
    const { accessToken } = yield select(selectTokens);
    // console.log('test.createAlert', _action.payload);
    const payload = yield call<TAsyncQuery2<IAlertRequestDTO, string, Partial<IDataAlert>>>(createAlertRequest.query, _action.payload!, accessToken);
    // console.log('test.createAlert', payload);
    yield put(action(EActions.CREATE_ALERT_OK, payload));
    yield put(action(EActions.REQUEST_ALERTS, { page: 1 }));
  } catch (error) {
    yield put(action(EActions.CREATE_ALERT_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}

export function* removeAlert(_action: IAction<IDataAlertDTO>): SagaIterator {
  try {
    const { accessToken } = yield select(selectTokens);
    yield call<TAsyncQuery2<IAlertRequestDTO, string, Partial<IDataAlert>>>(removeAlertRequest.query, _action.payload!, accessToken);
    yield put(action(EActions.REMOVE_ALERT_OK));
    yield put(action(EActions.REQUEST_ALERTS, { page: 1 }));
  } catch (error) {
    yield put(action(EActions.REMOVE_ALERT_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
