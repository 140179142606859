import { EActions } from '../../actions';
import { IAction } from '../../types';
import { IStateAuth, IDataAuth } from './types';

export const initial: IStateAuth = {
  auth: {
    id: undefined,
    login: undefined,
    password: undefined,
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function authReducer(state = initial, action: IAction<Partial<IDataAuth>>): IStateAuth {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.REQUEST_LOGIN:
      return {
        auth: {
          login: action.payload?.login,
          password: action.payload?.password,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_LOGIN_OK:
      return {
        auth: {
          ...state.auth,
          id: action.payload?.id,
          password: undefined,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_LOGIN_ERR:
      return {
        auth: {
          ...state.auth,
          password: undefined,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
    case EActions.EXIT:
      return {
        auth: { ...initial.auth },
      };
    default:
      return state;
  }
}
