import { initial } from './reducer';
import {
  IDataAuth,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";

const selectAuth = (state: IState): IDataAuth => {
  const { auth } = state;
  return auth.auth ? auth.auth : initial.auth;
};

const selectIsLogin = (state: IState): boolean => {
  const { auth } = state;
  return auth.auth ? !!auth.auth.id : false;
};

export const makeSelectAuth = () =>
    createSelector(selectAuth, substate => substate);

export const makeSelectIsLogin = () =>
    createSelector(selectIsLogin, substate => substate);

/* SELECTOR */

export {
  selectAuth,
  selectIsLogin,
  /* SELECTOR_EXPORT */
};
