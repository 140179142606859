import {BaseRequest} from './BaseRequest';
import {IAlertsRequestDTO, IAlertsResponseDTO, IDataAlerts} from '../types';
import {endpoint} from "../helpers";

export class AlertsRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = AlertsRequest.URL) {
    super(baseUrl);
  }

  public query = async (req: IAlertsRequestDTO, token: string): Promise<Partial<IDataAlerts>> => {
    return this.fetchJSON('v1/alerts', 'POST', req, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: IAlertsResponseDTO) => {
          return {
            list: r.data?.alerts,
            stateData: {
              loading: false,
            },
          };
        });
  };
}

export const alertsRequest = new AlertsRequest();
