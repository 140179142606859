export enum EActions {
  REQUEST_LOGIN = 'REQUEST_LOGIN',
  REQUEST_LOGIN_OK = 'REQUEST_LOGIN_OK',
  REQUEST_LOGIN_ERR = 'REQUEST_LOGIN_ERR',
  REQUEST_TOKEN = 'REQUEST_TOKEN',
  REQUEST_TOKEN_OK = 'REQUEST_TOKEN_OK',
  REQUEST_TOKEN_ERR = 'REQUEST_TOKEN_ERR',
  NAVIGATE_MAIN_TAB = 'NAVIGATE_MAIN_TAB',
  SET_LANGUAGE = 'SET_LANGUAGE',
  CHANGE_DICTIONARY = 'CHANGE_DICTIONARY',
  REQUEST_PAGE = 'REQUEST_PAGE',
  REQUEST_PAGE_OK = 'REQUEST_PAGE_OK',
  REQUEST_PAGE_ERR = 'REQUEST_PAGE_ERR',
  REQUEST_PUSH_TOKEN = 'REQUEST_PUSH_TOKEN',
  REQUEST_PUSH_TOKEN_OK = 'REQUEST_PUSH_TOKEN_OK',
  REQUEST_PUSH_TOKEN_ERR = 'REQUEST_PUSH_TOKEN_ERR',
  REGISTER_PUSH_TOKEN = 'REGISTER_PUSH_TOKEN',
  REGISTER_PUSH_TOKEN_OK = 'REQUEST_PUSH_TOKEN_OK',
  REGISTER_PUSH_TOKEN_ERR = 'REGISTER_PUSH_TOKEN_ERR',
  REQUEST_ALERTS = 'REQUEST_ALERTS',
  REQUEST_ALERTS_OK = 'REQUEST_ALERTS_OK',
  REQUEST_ALERTS_ERR = 'REQUEST_ALERTS_ERR',
  REQUEST_SYMBOLS = 'REQUEST_SYMBOLS',
  REQUEST_SYMBOLS_OK = 'REQUEST_SYMBOLS_OK',
  REQUEST_SYMBOLS_ERR = 'REQUEST_SYMBOLS_ERR',
  SET_FAVORITE = 'SET_FAVORITE',
  SET_FAVORITE_OK = 'SET_FAVORITE_OK',
  UNSET_FAVORITE = 'UNSET_FAVORITE',
  UNSET_FAVORITE_OK = 'UNSET_FAVORITE_OK',
  BEFORE_NEW_ALERT = 'BEFORE_NEW_ALERT',
  NEW_ALERT = 'NEW_ALERT',
  CREATE_ALERT = 'CREATE_ALERT',
  CREATE_ALERT_OK = 'CREATE_ALERT_OK',
  CREATE_ALERT_ERR = 'CREATE_ALERT_ERR',
  REMOVE_ALERT = 'REMOVE_ALERT',
  REMOVE_ALERT_OK = 'REMOVE_ALERT_OK',
  REMOVE_ALERT_ERR = 'REMOVE_ALERT_ERR',
  SEARCH = 'SEARCH',
  REQUEST_LAST_PRICE = 'REQUEST_LAST_PRICE',
  REQUEST_LAST_PRICE_OK = 'REQUEST_LAST_PRICE_OK',
  REQUEST_LAST_PRICE_ERR = 'REQUEST_LAST_PRICE_ERR',
  EXIT = 'EXIT',
  REQUEST_RSI = 'REQUEST_RSI',
  REQUEST_RSI_OK = 'REQUEST_RSI_OK',
  REQUEST_RSI_ERR = 'REQUEST_RSI_ERR',
  NEW_LOT = 'NEW_LOT',
  ADD_TO_LOT = 'ADD_TO_LOT',
  REMOVE_FROM_LOT = 'REMOVE_FROM_LOT',
  REQUEST_LOT = 'REQUEST_LOT',
  REQUEST_LOT_OK = 'REQUEST_LOT_OK',
  REQUEST_LOT_ERR = 'REQUEST_LOT_ERR',
  SET_LOT = 'SET_LOT',
  SET_LOT_OK = 'SET_LOT_OK',
  SET_LOT_ERR = 'SET_LOT_ERR',
  REMOVE_LOT = 'REMOVE_LOT',
  REMOVE_LOT_OK = 'REMOVE_LOT_OK',
  REMOVE_LOT_ERR = 'REMOVE_LOT_ERR',
}
