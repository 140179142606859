import React, {useCallback, useState} from 'react'
import {
    Confirm,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
    List,
    ListContent,
    Message,
    MessageHeader,
} from 'semantic-ui-react';
import {createStructuredSelector} from "reselect";
import {Dispatch} from "redux";
import {action, EActions} from "../../actions";
import {connect, ConnectedProps} from "react-redux";
import {IAlertDTO} from "../../types";
import {makeSelectAlerts} from "../../store/alerts";
import moment from "moment";
import {MetaAlert} from "../../helpers";
import {Scrollable} from "./Scrollable";

function sort(a: IAlertDTO, b: IAlertDTO) {
    if (a.dt > b.dt) {
        return -1;
    } else {
        return 1;
    }
}

interface IAlertsProps extends TAlertsProps {
}

const Alerts = (props: IAlertsProps) => {
    const [ selectedItem, setSelect ] = useState<IAlertDTO | undefined>();
    const [ openConfirmDelete, setOpenConfirmDelete ] = useState<boolean>(false);

    const confirm = useCallback(() => {
        setOpenConfirmDelete(false);
        if (selectedItem) {
            props.removeAlert(selectedItem.id);
        }
    }, [props.removeAlert, selectedItem, setOpenConfirmDelete]);

    const close = useCallback(() => {
        setOpenConfirmDelete(false);
    }, [setOpenConfirmDelete]);

    const deleteAlert = useCallback((dto: IAlertDTO) => {
        setSelect(dto);
        setOpenConfirmDelete(true);
    }, [setSelect, setOpenConfirmDelete]);

    if (props.alerts.stateData.error) {
        return (
            <Message negative>
                <MessageHeader>Ошибка</MessageHeader>
                <p>{props.alerts.stateData.error}</p>
            </Message>
        );
    }

    if (!props.alerts.list || props.alerts.list.length === 0) {
        return (
            <Message>
                <p>Список пуст.</p>
            </Message>
        );
    }

    return (
        <Scrollable>
            <List selection divided verticalAlign={'middle'}>
                {props.alerts.list
                    .sort(sort)
                    .map((i, index) => (
                        <Grid key={`${index}:${i.id}`}>
                            <GridRow columns={4}>
                                <GridColumn>
                                    {moment(i.dt).format("DD.MM.YYYY HH:mm")}
                                </GridColumn>
                                <GridColumn>
                                    <Icon name={[1,2,4].includes(i.condition) ? 'long arrow alternate up' : 'long arrow alternate down'}
                                          color={[1,2,4].includes(i.condition) ? 'green' : 'red'}
                                          size={'large'}
                                    />
                                    <ListContent as={'a'}>{i.name}</ListContent>
                                    {i.disposable == 0 && (
                                        <Icon className={'pl5px'} name={'redo'} color={'orange'} size={'small'} />
                                    )}
                                </GridColumn>
                                <GridColumn>
                                    <Header size='medium'>
                                        {[0, 1].includes(i.condition) ?
                                            (i.lastPrice ? `${i.lastPrice} -> ${i.val}` : i.val) :
                                            [2,3].includes(i.condition) ?
                                            `${i.xgrow}X / ${MetaAlert.getPeriodName(i.interval)}`:
                                            `RSI ${i.val} за ${MetaAlert.getPeriodName(i.interval)}`
                                        }
                                    </Header>
                                </GridColumn>
                                <GridColumn textAlign={'right'}>
                                    <Icon className={'pr1r'} name={'trash alternate'} size={'large'} onClick={() => deleteAlert(i)} />
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    ))}
            </List>
            <Confirm
                open={openConfirmDelete}
                content={`Удалить ${selectedItem?.name} ${selectedItem?.val} ?`}
                onCancel={close}
                onConfirm={confirm}
            />
        </Scrollable>
    );
}

const mapStateToProps = createStructuredSelector({
    alerts: makeSelectAlerts(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        removeAlert: (id: number) => dispatch(action(EActions.REMOVE_ALERT, { id })),
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type TAlertsProps = ConnectedProps<typeof connector>

export default connector(Alerts);
