import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Alert, Login, Main, Lot} from './pages';
import 'semantic-ui-css/semantic.min.css'
import {createStructuredSelector} from 'reselect';
import GlobalStyle from './global-styles';
import {connect, ConnectedProps} from "react-redux";
import {makeSelectIsLogin} from "./store/auth";
import {makeSelectIsTokens} from "./store/token/selector";
import {Dispatch} from "redux";
import {action, EActions} from "./actions";

interface IAppProps extends TAppProps {
}

function App(props: IAppProps) {

    const navigate = useNavigate();

    useEffect(() => {
        if (!props.isTokens) {
            props.refreshToken();
        }
        if (!props.isLogin) {
            navigate('/login');
        }
    }, [props.isTokens, props.isLogin]);

    useEffect(() => {
        if (props.isLogin) {
            props.requestSymbols();
            props.requestAlerts();
            const intervalId = setInterval(() => {
                props.requestAlerts();
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [props.isLogin, props.requestSymbols, props.requestAlerts]);

    return (
        <>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/" element={<Main/>} />
                <Route path="/alert" element={<Alert/>} />
                <Route path="/lot" element={<Lot/>} />
            </Routes>
            <GlobalStyle/>
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    isLogin: makeSelectIsLogin(),
    isTokens: makeSelectIsTokens(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        refreshToken: () => dispatch(action(EActions.REQUEST_TOKEN, {})),
        requestSymbols: () => dispatch(action(EActions.REQUEST_SYMBOLS)),
        requestAlerts: (page?: number) => dispatch(action(EActions.REQUEST_ALERTS, { page })),
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type TAppProps = ConnectedProps<typeof connector>

export default connector(App);
