import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {alertsRequest} from '../request';
import {IAction, IAlertsRequestDTO, IDataAlerts, TAsyncQuery2} from "../types";
import {selectAuth, selectTokens} from '../store/selectors';
import {checkHttpError} from '../helpers';

/* SAGA */

export function* alerts(_action: IAction<IDataAlerts>): SagaIterator {
  try {
    const { id } = yield select(selectAuth);
    const { accessToken } = yield select(selectTokens);

    const req: IAlertsRequestDTO = {
      id_user: id,
    };

    const payload = yield call<TAsyncQuery2<IAlertsRequestDTO, string, Partial<IDataAlerts>>>(alertsRequest.query, req, accessToken);
    yield put(action(EActions.REQUEST_ALERTS_OK, payload));
  } catch (error) {
    yield put(action(EActions.REQUEST_ALERTS_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
