import { createStore, compose, applyMiddleware, Middleware, Store } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { createReducer } from './reducers';
import { sagas } from '../sagas';

const middleware: Middleware[] = [];

const sagaMiddleware: Middleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

// if (__DEV__) {
  // middleware.push(createLogger());
// }

const reducers = createReducer();
// const enhancers = [applyMiddleware(...middleware)];

const store = createStore(reducers, {}, compose(applyMiddleware(...middleware)));

const configureStore = (): Store => {
  return store;
};

(sagaMiddleware as SagaMiddleware).run(sagas);

export default configureStore;
