import React, {useCallback} from 'react'
import {FormCheckbox, List, ListContent, ListIcon, ListItem, Loader} from 'semantic-ui-react';
import {createStructuredSelector} from "reselect";
import {Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {makeSelectSearch} from "../../store/search";
import {Scrollable} from "./Scrollable";
import {action, EActions} from "../../actions";
import {ILotSymbol} from "../../types";

function sort(a: ILotSymbol, b: ILotSymbol) {
    if (a.checked && !b.checked) {
        return -1;
    } else {
        return 1;
    }
}

interface ILotSymbolsProps extends TLotSymbolsProps {
    loading: boolean;
    list: ILotSymbol[];
}

const LotSymbols = (props: ILotSymbolsProps) => {
    const onChangeChecked = useCallback(
        (item: ILotSymbol, checked: boolean) => {
            if (checked) {
                props.add(item.id);
            } else {
                props.remove(item.id);
            }
        },
        [props.add, props.remove],
    );

    if (props.loading) {
        return <Loader inverted>Loading</Loader>;
    }

    return (
        <Scrollable height={'60vh'}>
            <List selection divided verticalAlign={'middle'}>
                {props.list.sort(sort).filter(i => props.search.text ? i.name.includes(props.search.text) : true).map(i => (
                    <ListItem key={i.id}>
                        <ListIcon verticalAlign={'middle'}>
                            <FormCheckbox checked={i.checked} onChange={
                                (_, data) => onChangeChecked(i, !!data.checked)
                            } />
                        </ListIcon>
                        <ListContent as='a' style={{ height:'3rem' }}>
                            {`${i.name}`}
                        </ListContent>
                    </ListItem>
                ))}
            </List>
        </Scrollable>
    );
}

const mapStateToProps = createStructuredSelector({
    search: makeSelectSearch(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        add: (id_symbol: number) => dispatch(action(EActions.ADD_TO_LOT, { id_symbol })),
        remove: (id_symbol: number) => dispatch(action(EActions.REMOVE_FROM_LOT, { id_symbol }))
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type TLotSymbolsProps = ConnectedProps<typeof connector>

export default connector(LotSymbols);
