import { initial } from './reducer';
import {
  IDataAlert,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";

const selectAlert = (state: IState): IDataAlert => {
  const { alert } = state;
  return alert.alert ? alert.alert : initial.alert;
};

export const makeSelectAlert = () =>
    createSelector(selectAlert, substate => substate);

/* SELECTOR */

export {
  selectAlert,
  /* SELECTOR_EXPORT */
};
