import {combineReducers, Reducer} from 'redux';
/* REDUCER_IMPORT */
import {navigationReducer} from './navigation/reducer';
import {IStateNavigation, IStateAuth, IStateToken} from "./types";
import {authReducer} from './auth/reducer';
import {tokenReducer} from './token/reducer';
import {IStatePushToken} from "./pushtoken/types";
import {pushTokenReducer} from "./pushtoken/reducer";
import {IStateAlert} from "./alert/types";
import {alertReducer} from "./alert/reducer";
import {IStateAlerts} from "./alerts/types";
import {alertsReducer} from './alerts/reducer';
import {IStateSymbols} from "./symbols/types";
import {symbolsReducer} from "./symbols/reducer";
import {IStateSearch} from "./search/types";
import {searchReducer} from "./search/reducer";
import {IStateSymbolInfo} from "./info/types";
import {infoReducer} from './info/reducer';
import {IStateLot} from './lot/types';
import {lotReducer} from './lot/reducer';


export interface IState {
  auth: IStateAuth;
  tokens: IStateToken;
  pushToken: IStatePushToken;
  navigation: IStateNavigation;
  alerts: IStateAlerts;
  symbols: IStateSymbols;
  alert: IStateAlert;
  search: IStateSearch;
  info: IStateSymbolInfo;
  lot: IStateLot;
}

export function createReducer(): Reducer {
  return combineReducers({
    /* REDUCER_INJECT */
    auth: authReducer,
    tokens: tokenReducer,
    pushToken: pushTokenReducer,
    navigation: navigationReducer,
    alerts: alertsReducer,
    symbols: symbolsReducer,
    alert: alertReducer,
    search: searchReducer,
    info: infoReducer,
    lot: lotReducer,
  });
}
