import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 12px; 
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .col {
    display: flex;
    flex-direction: column;
  }
  
  .jcCenter {
    display: flex;
    justify-content: center;
  }

  .jcEnd {
    display: flex;
    justify-content: flex-end;
  }
  
  .aiCenter {
    display: flex;
    align-items: center;
  }

  .aiEnd {
    display: flex;
    align-items: flex-end;
  }

  .aiBase {
    display: flex;
    align-items: baseline;
  }
  
  .pa1r {
    padding: 1rem;
  }

  .pt5 {
    padding-top: 5px;
  }

  .mt5 {
    margin-top: 5px;
  }

  .pl5px {
    padding-left: 5px;
  }

  .pt1r {
    padding-top: 1rem;
  }
  
  .pt2r {
    padding-top: 2rem;
  }

  .pt3r {
    padding-top: 3rem;
  }
  
  .pb1r {
    padding-bottom: 1rem;
  }

  .pb2r {
    padding-bottom: 2rem;
  }

  .mb2r {
    margin-bottom: 2rem;
  }

  .ml1r {
    margin-left: 1rem;
  }

  .mr1r {
    margin-right: 1rem;
  }

  .pl1r {
    padding-left: 1rem;
  }

  .pl2r {
    padding-left: 2rem;
  }

  .pl3r {
    padding-left: 3rem;
  }

  .pr1r {
    padding-right: 1rem;
  }

  .pr2r {
    padding-right: 2rem;
  }

  .pr3r {
    padding-right: 3rem;
  }
  
  .pb5 {
    padding-bottom: 5px;
  }

  .pb10 {
    padding-bottom: 10px;
  }

  .pb20 {
    padding-bottom: 20px;
  }

  .pb200 {
    padding-bottom: 200px;
  }
  
  .flex1 {
    display: flex;
    flex: 1;
  }

  .alh {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .ach {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .arh {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .inb {
    display: inline-block;
  }
  
  .m2r {
    margin: 2rem;
  }

  .p4h {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  
  .mt-3r {
    margin-top: -3rem;
  }

  .mt3r {
    margin-top: 3rem;
  }
  
  .pt3r {
    padding-top: 3rem;
  }

  .ml3r {
    margin-left: 3rem;
  }

  .ph5r {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pl5r {
    padding-left: 5rem;
  }

  .mr2r {
    margin-right: 2rem;
  }

  .pr2r {
    padding-right: 2rem;
  }
  
  .z10 {
    z-index: 10;
  }
  
  .bg {
    background: #ddffdd;
  }

  .shadow-bottom {
    margin-bottom: 0;
    box-shadow: 1px 1px 1px #aaaaaa !important;
  },
  
  .margin0 {
    margin: 0;
  }
  
  .gradient {
    background: linear-gradient(0deg, rgba(221,221,221,0.4) 0%, rgba(110,223,136,0.8) 100%);
  }

  .bg_white {
    background: white;
  }
  
  .nomargin {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 856px) {
    .ph5r {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
`;

export default GlobalStyle;
