
export interface IStatItem {
    readonly key: string;
    text?: string;
    value?: string;
}

export enum EDDeviceFormFactor {
    Mobile = 'Mobile',
    Desktop = 'Desktop',
}

