import {BaseRequest} from './BaseRequest';
import {endpoint} from "../helpers";
import {IRSIResponseDTO} from "../types/dto/rsi";
import {IDataSymbolInfo, ISymbolInfo} from "../store/info/types";

export class RSIRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = RSIRequest.URL) {
    super(baseUrl);
  }

  public query = async (token: string, id_symbol: number, interval: number): Promise<Partial<IDataSymbolInfo>> => {
    return this.fetchJSON(`v1/bybit/symbol/rsi/${id_symbol}/${interval}`, 'GET', undefined, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: IRSIResponseDTO) => {
          const info: ISymbolInfo = {};
          info[r.data!.symbol] = r.data!;
          // console.log('test.RSIRequest', {
          //     info,
          // });
          return {
            info,
          };
        });
  };
}

export const rsiRequest = new RSIRequest();
