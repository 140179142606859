import { BaseRequest } from './BaseRequest';
import {IAuthRequestDTO, IAuthResponseDTO, IDataAuth} from '../types';
import {endpoint} from "../helpers";

export class AuthRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = AuthRequest.URL) {
    super(baseUrl);
  }

  public query = async (req: IAuthRequestDTO, token: string): Promise<Partial<IDataAuth>> => {
    return this.fetchJSON('v1/signin', 'POST', req, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: IAuthResponseDTO) => {
          return {
            login: r.data?.login,
            id: r.data?.id,
            stateData: {
              loading: false,
            },
          };
        });
  };
}

export const authRequest = new AuthRequest();
