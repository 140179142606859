import { EActions } from '../../actions';
import {IAction} from '../../types';
import { IStateAlerts, IDataAlerts } from './types';

export const initial: IStateAlerts = {
  alerts: {
    pageSize: 20,
    page: 0,
    links: undefined,
    list: [],
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function alertsReducer(state = initial, action: IAction<Partial<IDataAlerts>>): IStateAlerts {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.REQUEST_ALERTS:
      return {
        alerts: {
          ...state.alerts,
//          list: [],
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_ALERTS_OK:
      return {
        alerts: {
          ...state.alerts,
          list: [ ...(action.payload?.list || []) ],
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_ALERTS_ERR:
      return {
        alerts: {
          ...state.alerts,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
    default:
      return state;
  }
}
