import { EActions } from '../../actions';
import {IAction} from '../../types';
import { IStatePushToken, IDataPushToken } from './types';

export const initial: IStatePushToken = {
  pushToken: {
    token: undefined,
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function pushTokenReducer(state = initial, action: IAction<Partial<IDataPushToken>>): IStatePushToken {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.REQUEST_PUSH_TOKEN:
      return {
        pushToken: {
          ...state.pushToken,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_PUSH_TOKEN_OK:
      return {
        pushToken: {
          ...state.pushToken,
          token: action.payload?.token,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_PUSH_TOKEN_ERR:
      return {
        pushToken: {
          ...state.pushToken,
          token: undefined,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
    default:
      return state;
  }
}
