import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Form,
    FormInput,
    Header,
    Icon,
    Message,
    Segment,
    Input, HeaderContent
} from 'semantic-ui-react';
import {createStructuredSelector} from "reselect";
import {makeSelectFormFactor} from "../../store/deviceInfo/selector";
import {Dispatch} from "redux";
import {action, EActions} from "../../actions";
import {connect, ConnectedProps} from "react-redux";
import {ILotDTO} from "../../types";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {useNavigate} from "react-router-dom";
import {makeSelectLot, makeSelectLots} from "../../store/lot";
import LotSymbols from "../components/LotSymbols";

export interface ILotProps extends TLotProps {
}

const Lot = (props: ILotProps) => {
    const [name, setName] = useState<string>(props.lots.lot?.name ?? '');
    const [search, setSearch] = useState<string>('');
    const [isSubmit, setSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (props.lots.lot?.name) {
            setName(props.lots.lot?.name);
        }
    }, [setName, props.lots.lot?.name]);

    const navigate = useNavigate();
    const onBack = useCallback(() => {
            navigate(-1);
        },
        [navigate],
    );

    const onSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setSearch(data.value ? data.value.toUpperCase() : '');
        },
        [setSearch],
    );

    const onNameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setName(data.value.toUpperCase());
        },
        [setName],
    );

    const onSet = useCallback(
        () => {
            props.setLot({
                id: props.lots.lot?.id ?? 0,
                name,
                ids: props.symbols.filter(i => i.checked).map(i => i.id),
            });
            setSubmit(true);
        },
        [name, props.setLot, props.lots, props.symbols, setSubmit],
    );

    useEffect(() => {
        if (isSubmit && !props.lots.stateData.error && props.lots.stateData.loading == false) {
            navigate(-1);
            props.navigateToSymbols();
        }
    }, [isSubmit, navigate, props.lots.stateData, props.navigateToSymbols]);

    const title = props.lots.lot?.id ? 'Редактирование группы' : 'Cоздание группы';
    const selected = props.lots.lot?.ids?.length ?? 0;
    const isValid = name.length > 4 && selected > 1;
    const list = props.symbols
        .filter(i => !i.lot)
        .filter(i => search ? i.name.includes(search) : true);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1rem' }}>
                <Button icon basic labelPosition='left' onClick={onBack}>
                    <Icon name='angle left' /> назад
                </Button>
                <div style={{ paddingLeft: '1rem' }}>
                    <Header as='h2' content={title}></Header>
                </div>
            </div>
            <Segment attached='bottom'>
                <Form unstackable>
                    <FormInput label='Имя группы' placeholder='Имя группы'
                        value={name} onChange={onNameChange} />
                    <div style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
                        <Header as='h5' className={'aiBase'}>
                            {'Tорговых пар в группe: '}
                            <HeaderContent as='h3' className={'pl1r'}>{selected}</HeaderContent>
                        </Header>
                        <div className={'pb1r'}>
                            <Input icon='search' placeholder='Торговая пара...' onChange={onSearch} />
                        </div>
                        <LotSymbols loading={props.lots.stateData.loading} list={list} />
                    </div>
                    <Button type='submit' disabled={!isValid} loading={props.lots.stateData.loading} onClick={onSet}>Сохранить</Button>
                </Form>
                {!!props.lots.stateData.error && (
                    <Message warning>
                        <p>props.lots.stateData.error</p>
                    </Message>
                )}
            </Segment>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    formFactor: makeSelectFormFactor(),
    lots: makeSelectLot(),
    symbols: makeSelectLots(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        setLot: (lot: ILotDTO) => dispatch(action(EActions.SET_LOT, { lot })),
        searchChange: (text?: string) => dispatch(action(EActions.SEARCH, { text })),
        navigateToSymbols: () => dispatch(action(EActions.NAVIGATE_MAIN_TAB, { tabIndex: 0 })),
    };
}

export const connector = connect(mapStateToProps, mapDispatchToProps);

type TLotProps = ConnectedProps<typeof connector>

export default connector(Lot);
