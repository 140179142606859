import { initial } from './reducer';
import {
  IDataSearch,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";

const selectSearch = (state: IState): IDataSearch => {
  const { search } = state;
  return search.search ? search.search : initial.search;
};

export const makeSelectSearch = () =>
    createSelector(selectSearch, substate => substate);

/* SELECTOR */

export {
  selectSearch,
  /* SELECTOR_EXPORT */
};
