import React, {useCallback, useEffect, useState} from "react";
import { Button, Form, FormInput, Header, Icon, Message, Segment, FormSelect, FormCheckbox } from 'semantic-ui-react';
import {createStructuredSelector} from "reselect";
import {makeSelectFormFactor} from "../../store/deviceInfo/selector";
import {Dispatch} from "redux";
import {action, EActions} from "../../actions";
import {connect, ConnectedProps} from "react-redux";
import {makeSelectAlert} from "../../store/alert";
import {IDataAlertDTO} from "../../types";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {useNavigate} from "react-router-dom";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { MetaAlert } from "../../helpers";
import {CheckboxProps} from "semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox";
import {makeSelectInfo} from "../../store/info";

export interface IAlertProps extends TAlertProps {
}

const Alert = (props: IAlertProps) => {
    const [permanent, setPermanent] = useState<boolean>(false);
    const [condition, setCondition] = useState<number>(1);
    const [intervalRSI, setIntervalRSI] = useState<number>(MetaAlert.day * 14);
    const [interval, setInterval] = useState<number>(MetaAlert.hour);
    const [xGrow, setXgrow] = useState<string | undefined>();
    const [value, setValue] = useState<string | undefined>();
    const [msg, setMsg] = useState<string>('');
    const [valid, setValid] = useState<boolean>(false);

    const navigate = useNavigate();
    const onBack = useCallback(() => {
            navigate(-1);
        },
        [navigate],
    );

    useEffect(() => {
        if (props.alert.id) {
            navigate(-1);
            props.navigateToAlerts();
        }
    }, [navigate, props.alert.id, props.navigateToAlerts]);

    const onPermanentChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps)=> {
            setPermanent(!!data.checked);
        },
        [setPermanent],
    );

    const onConditionChange = useCallback(
        (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            const newCondition = Number(data.value);
            setCondition(newCondition);
            if (condition !== newCondition) {
                setXgrow(undefined);
                setValue(undefined);
                setValid(false);
            }
            if ([4, 5].includes(newCondition) && ![4, 5].includes(condition)) {
                // console.log('test.onConditionChange', {
                //     id_symbol: props.alert.symbol?.id,
                //     intervalRSI,
                //     newCondition,
                // })
                if (props.alert.symbol?.id) {
                    props.refreshRSI(props.alert.symbol.id, intervalRSI);
                }
            }
        },
        [condition, setCondition, setXgrow, setValue, setValid, props.refreshRSI, props.alert.symbol, intervalRSI],
    );

    const onIntervalChange = useCallback(
        (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            setInterval(Number(data.value));
        },
        [setInterval],
    );

    const onIntervalRSIChange = useCallback(
        (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            setIntervalRSI(Number(data.value));
            if (props.alert.id_symbol) {
                props.refreshRSI(props.alert.id_symbol, Number(data.value));
            }
        },
        [setIntervalRSI, props.alert.id_symbol, props.refreshRSI],
    );

    const onValueChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setXgrow('0');
            setValue(data.value);
            setValid(MetaAlert.validateNumerics([data.value]));
        },
        [setValue, setXgrow, setValid],
    );

    const onXChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setValue('0');
            setXgrow(data.value);
            setValid(MetaAlert.validateNumerics([data.value]));
        },
        [setXgrow, setValue, setValid],
    );

    const onRSIChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setXgrow('0');
            setValue(data.value);
            setValid(MetaAlert.validateRSI(data.value));
        },
        [setValue, setXgrow, setValid],
    );

    const onMsgChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setMsg(data.value);
        },
        [setMsg],
    );

    const onCreate = useCallback(
        () => {
            const val = Number(value);
            const _xGrow = Number(xGrow);
            props.create({
                ...props.alert,
                val: val,
                condition: condition === 1 ? (val > (props.alert.symbol?.lastPrice ?? 0) ? 1 : 0) : condition,
                msg,
                interval: [4, 5].includes(condition) ? intervalRSI : interval,
                xgrow: _xGrow,
                disposable: permanent ? 0 : 1,
            });
        },
        [permanent, interval, intervalRSI, value, xGrow, msg, props.create, props.alert],
    );

    const grow = [1, 2, 4].includes(condition) ? 'роста' : 'падения';
    const rsi = props.alert.symbol ? props.info[props.alert.symbol.name]?.rsi : '';
    const isLot = props.alert.symbol?.lot === 1;

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1rem' }}>
                <Button icon basic labelPosition='left' onClick={onBack}>
                    <Icon name='angle left' /> назад
                </Button>
                <div style={{ paddingLeft: '1rem' }}>
                    <Header as='h2' content='Cоздание оповещения'></Header>
                </div>
            </div>
            <Segment attached='bottom'>
                <Form unstackable>
                    <FormInput readOnly label='Торговая пара' placeholder='Торговая пара' value={props.alert.symbol?.name} />
                    {isLot ? null : condition < 4 ? (
                        <FormInput readOnly label='Текущая цена' value={props.alert.symbol?.lastPrice}/>
                    ) : (
                        <FormInput readOnly label='Текущий RSI' placeholder='нет данных' value={rsi ?? ''}/>
                    )}
                    <FormSelect label='Тип уведомления' value={condition} onChange={onConditionChange}
                        options={isLot ? MetaAlert.conditions.filter((_, i) => i !== 0) : MetaAlert.conditions} />
                    {condition === 1 ? (
                        <FormInput label='Цена уведомления' placeholder='0.00...' onChange={onValueChange} error={!valid} value={value ?? ''} />
                    ) : condition < 4 ? (
                        <>
                            <FormInput label={`Коэффициент ${grow} X за ${MetaAlert.getPeriodName(interval)}`} placeholder='0.00...' onChange={onXChange} error={!valid} value={xGrow ?? ''} />
                            <FormSelect fluid label='Период' options={MetaAlert.intervals} value={interval} onChange={onIntervalChange} />
                        </>
                    ) : (
                        <>
                            <FormInput label={`RSI за ${MetaAlert.getPeriodName(intervalRSI)}`} placeholder='0.00...' onChange={onRSIChange} error={!valid} value={value ?? ''} />
                            <FormSelect fluid label='Период' options={MetaAlert.periods} value={intervalRSI} onChange={onIntervalRSIChange} />
                        </>
                    )}
                    <FormInput label='Пометка' placeholder='Текст сообщения' onChange={onMsgChange} />
                    <FormCheckbox className={'pt1r pb2r'} label='Постоянное оповещение' onChange={onPermanentChange} />
                    <Button type='submit' disabled={!valid} onClick={onCreate} loading={props.alert.stateData.loading}>Создать оповещение</Button>
                </Form>
                {!!props.alert.stateData.error && (
                    <Message warning>
                        <p>props.alert.stateData.error</p>
                    </Message>
                )}
            </Segment>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    formFactor: makeSelectFormFactor(),
    alert: makeSelectAlert(),
    info: makeSelectInfo(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        create: (payload: IDataAlertDTO) => dispatch(action(EActions.CREATE_ALERT, payload)),
        navigateToAlerts: () => dispatch(action(EActions.NAVIGATE_MAIN_TAB, { tabIndex: 1 })),
        refreshRSI: (id_symbol: number, interval: number) => dispatch(action(EActions.REQUEST_RSI, { id_symbol, interval })),
    };
}

export const connector = connect(mapStateToProps, mapDispatchToProps);

type TAlertProps = ConnectedProps<typeof connector>

export default connector(Alert);
