import React, {useCallback, useState} from "react";
import {Button, Confirm, Header, Icon, Input, MenuItem, Segment, Tab, TabPane} from 'semantic-ui-react';
import {createStructuredSelector} from "reselect";
import {Dispatch} from "redux";
import {action, EActions} from "../../actions";
import {connect, ConnectedProps} from "react-redux";
import {makeSelectFormFactor, makeSelectTabIndex} from "../../store/selectors";
import {SemanticShorthandItem} from "semantic-ui-react/dist/commonjs/generic";
import {TabPaneProps} from "semantic-ui-react/dist/commonjs/modules/Tab/TabPane";
import {Alerts, Completed, RootContainer, Symbols} from "../components";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {TabProps} from "semantic-ui-react/dist/commonjs/modules/Tab/Tab";
import {useNavigate} from "react-router-dom";

const panes = [
    {
        menuItem: (
            <MenuItem key='symbols'>
                {'Торговые пары'}{/*<Label>{''}</Label>*/}
            </MenuItem>
        ),
        render: () => <TabPane><Symbols /></TabPane>,
    },
    {
        menuItem: (
            <MenuItem key='active'>
                {'Оповещения'}{/*<Label>{''}</Label>*/}
            </MenuItem>
        ),
        render: () => <TabPane><Alerts /></TabPane>,
    },
    {
        menuItem: (
            <MenuItem key='complete'>
                {'Исполненные'}{/*<Label>{''}</Label>*/}
            </MenuItem>
        ),
        render: () => <TabPane><Completed /></TabPane>,
    },
]

export interface IMainProps extends TMainProps {
    panes?: SemanticShorthandItem<TabPaneProps>;
}

const Main = (props: IMainProps) => {
    const [ openConfirmExit, setOpenConfirmExit ] = useState<boolean>(false);
    const navigate = useNavigate();

    const confirm = useCallback(() => {
        setOpenConfirmExit(false);
        props.exit();
    }, [props.exit, setOpenConfirmExit]);

    const openExit = useCallback(() => {
        setOpenConfirmExit(true);
    }, [setOpenConfirmExit]);

    const closeExit = useCallback(() => {
        setOpenConfirmExit(false);
    }, [setOpenConfirmExit]);

    const handleTabChange = useCallback((event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
        props.navigateToTab(Number(data.activeIndex));
    }, [props.navigateToTab]);

    const onSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            props.searchChange(data.value ? data.value.toUpperCase() : undefined);
        },
        [props.searchChange],
    );

    const navigateToLot = useCallback(
        () => {
            props.createLot();
            navigate('/lot');
        },
        [props.createLot, navigate],
    );

    // return props.formFactor === EDDeviceFormFactor.Desktop ? <MainLg {...props} panes={panes} /> : <MainXs {...props} panes={panes} />;
    return (
        <RootContainer>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '1rem' }}>
                <div>
                    <Header as='h2' content='Brungade Trading' />
                </div>
                <div>
                    <Button icon basic labelPosition='right' onClick={openExit}>
                        <Icon name='sign out' /> ВЫХОД
                    </Button>
                </div>
            </div>
            <Segment attached='bottom'>
                <div>
                    <Input className={'mr1r'} icon='search' placeholder='Торговая пара...' onChange={onSearch} />
                    <Button icon={'plus'} onClick={navigateToLot} />
                </div>
                <Tab className={'pt1r'} activeIndex={props.activeIndex} panes={panes} onTabChange={handleTabChange} />
            </Segment>
            <Confirm
                open={openConfirmExit}
                content={`Выйти?`}
                onCancel={closeExit}
                onConfirm={confirm}
            />
        </RootContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    formFactor: makeSelectFormFactor(),
    activeIndex: makeSelectTabIndex(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        searchChange: (text?: string) => dispatch(action(EActions.SEARCH, { text })),
        navigateToTab: (tabIndex: number) => dispatch(action(EActions.NAVIGATE_MAIN_TAB, { tabIndex })),
        exit: () => dispatch(action(EActions.EXIT)),
        createLot: () => dispatch(action(EActions.NEW_LOT)),
    };
}

export const connector = connect(mapStateToProps, mapDispatchToProps);

type TMainProps = ConnectedProps<typeof connector>

export default connector(Main);
