import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {IDataToken} from "../types";
import {firebaseApp, vapidKey} from '../provider';

const messaging = getMessaging(firebaseApp);

onMessage(messaging, (payload) => {
    // console.log('Message received. ', payload);
    // Update the UI to include the received message.
});

export class PushTokenRequest {
    public query = async (): Promise<Partial<IDataToken>> => {
        return Notification.requestPermission().
            then(permission => {
                if (permission === 'granted') {
                    // console.log('pushToken.granted', {
                    //     vapidKey,
                    // });
                    return getToken(messaging, { vapidKey })
                        .then((currentToken) => {
                            // console.log('test.PushTokenRequest', {
                            //     currentToken,
                            // });

                            // eAgFFV-_sfknLIEwEgMAIr:APA91bEzXHyW294oBYVbrx9QFWB…KZAeYiWTYrMvES4bWCVd_IQYHYRjAZtBtjWssc3mKtZd_GA7-
                            // eAgFFV-_sfknLIEwEgMAIr:APA91bEzXHyW294oBYVbrx9QFWB…KZAeYiWTYrMvES4bWCVd_IQYHYRjAZtBtjWssc3mKtZd_GA7-
                            if (currentToken) {
                                return {
                                    token: currentToken,
                                    stateData: {
                                        loading: false,
                                    },
                                };
                            } else {
                                throw new Error('No registration push token available. Request permission to generate one.');
                            }
                        })
                        .catch(e => {
                            console.log('test.PushTokenRequest', {
                                e,
                            });
                            throw e;
                        })
                } else {
                    throw new Error('Unable to get permission to push notify.');
                }
            });
    };
}

export const pushTokenRequest = new PushTokenRequest();
