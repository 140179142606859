import { createSelector } from 'reselect';
import { initial } from './reducer';
import {
  IDataPushToken,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";

const selectPushToken = (state: IState): IDataPushToken => {
  const { pushToken } = state;
  return pushToken.pushToken ? pushToken.pushToken : initial.pushToken;
};

export const makeSelectPushToken = () =>
    createSelector(selectPushToken, substate => substate);


/* SELECTOR */

export {
  selectPushToken,
  /* SELECTOR_EXPORT */
};
