import { createSelector } from 'reselect';
import { initial } from './reducer';
import {
  IDataToken,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";

const selectTokens = (state: IState): IDataToken => {
  const { tokens } = state;
  return tokens.tokens ? tokens.tokens : initial.tokens;
};

const selectIsTokens = (state: IState): boolean => {
  const { tokens } = state;
  return !!tokens.tokens.accessToken;
};

const selectPK = (state: IState): string | undefined => {
  const { tokens } = state;
  return tokens.tokens.publicKey;
};

export const makeSelectTokens = () =>
    createSelector(selectTokens, substate => substate);

export const makeSelectIsTokens = () =>
    createSelector(selectIsTokens, substate => substate);

export const makeSelectPK = () =>
    createSelector(selectPK, substate => substate);

/* SELECTOR */

export {
  selectTokens,
  selectIsTokens,
  selectPK,
  /* SELECTOR_EXPORT */
};
