import { initial } from './reducer';
import {
  ISymbolInfo,
  /* SELECTOR_IMPORT */
} from './types';
import {IState} from "../reducers";
import {createSelector} from "reselect";

const selectInfo = (state: IState): ISymbolInfo => {
  const { info } = state;
  return info.info.info ? info.info.info : initial.info.info;
};

export const makeSelectInfo = () =>
    createSelector(selectInfo, substate => substate);

/* SELECTOR */

export {
  selectInfo,
  /* SELECTOR_EXPORT */
};
