import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {rsiRequest} from '../request';
import {IAction, TAsyncQuery3} from "../types";
import {selectTokens} from '../store/selectors';
import {checkHttpError} from '../helpers';
import {IDataSymbolInfo} from "../store/info/types";

/* SAGA */

export function* rsi(_action: IAction<IDataSymbolInfo>): SagaIterator {
  try {
    const { id_symbol, interval } = _action.payload!;
    const tokens = yield select(selectTokens);
    const payload = yield call<TAsyncQuery3<string, number, number, Partial<IDataSymbolInfo>>>(rsiRequest.query,
        tokens.accessToken, id_symbol, interval);
    yield put(action(EActions.REQUEST_RSI_OK, payload));
  } catch (error) {
    yield put(action(EActions.REQUEST_RSI_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
