import { EActions } from '../../actions';
import { IAction } from '../../types';
import { IStateNavigation, IDataNavigation } from './types';

export const initial: IStateNavigation = {
  navigation: {
    tabIndex: 0,
    params: {},
  },
};

export function navigationReducer(state = initial, action: IAction<Partial<IDataNavigation>>): IStateNavigation {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.NAVIGATE_MAIN_TAB:
      return {
        navigation: {
          ...state.navigation,
          tabIndex: action.payload?.tabIndex ?? initial.navigation.tabIndex,
          params: { ...action.payload?.params },
        },
      };
    default:
      return state;
  }
}
