import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {setFavoriteRequest, unsetFavoriteRequest} from '../request';
import {IAction, IDataFavorite, TAsyncQuery3} from "../types";
import {selectAuth, selectTokens} from '../store/selectors';

/* SAGA */

export function* setFavorite(_action: IAction<IDataFavorite>): SagaIterator {
  try {
    const { id,  } = yield select(selectAuth);
    const { accessToken } = yield select(selectTokens);

    const payload = yield call<TAsyncQuery3<string, number, number, boolean>>(setFavoriteRequest.query, accessToken, id, _action.payload!.id_symbol);
    if (payload) {
      yield put(action(EActions.SET_FAVORITE_OK, _action.payload));
    }
  } catch (error) {
  }
}

export function* unsetFavorite(_action: IAction<IDataFavorite>): SagaIterator {
  try {
    const { id,  } = yield select(selectAuth);
    const { accessToken } = yield select(selectTokens);

    const payload = yield call<TAsyncQuery3<string, number, number, boolean>>(unsetFavoriteRequest.query, accessToken, id, _action.payload!.id_symbol);
    if (payload) {
      yield put(action(EActions.UNSET_FAVORITE_OK, _action.payload));
    }
  } catch (error) {
  }
}
