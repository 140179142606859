import { SagaIterator } from '@redux-saga/core';
import { takeEvery, all } from '@redux-saga/core/effects';
import { EActions } from '../actions';
import {login} from './auth';
import {token} from './token';
import {pushToken} from "./pushToken";
import {alerts} from './alerts';
import {symbols} from "./symbols";
import { setFavorite, unsetFavorite } from './favorites';
import {beforeNewAlert, createAlert, removeAlert} from './alert';
import { lastPrice } from "./lastPrice";
import { rsi } from "./rsi";
import { lot, lotSet } from './lot';

/* SAGA_IMPORT */

export default function* watch(): SagaIterator {
  yield all([takeEvery(EActions.REQUEST_LOGIN, login)]);
  yield all([takeEvery(EActions.REQUEST_TOKEN, token)]);
  yield all([takeEvery(EActions.REQUEST_PUSH_TOKEN, pushToken)]);
  yield all([takeEvery(EActions.REQUEST_ALERTS, alerts)]);
  yield all([takeEvery(EActions.REQUEST_SYMBOLS, symbols)]);
  yield all([takeEvery(EActions.SET_FAVORITE, setFavorite)]);
  yield all([takeEvery(EActions.UNSET_FAVORITE, unsetFavorite)]);
  yield all([takeEvery(EActions.CREATE_ALERT, createAlert)]);
  yield all([takeEvery(EActions.REMOVE_ALERT, removeAlert)]);
  yield all([takeEvery(EActions.REQUEST_LAST_PRICE, lastPrice)]);
  yield all([takeEvery(EActions.BEFORE_NEW_ALERT, beforeNewAlert)]);
  yield all([takeEvery(EActions.REQUEST_RSI, rsi)]);
  yield all([takeEvery(EActions.REQUEST_LOT, lot)]);
  yield all([takeEvery(EActions.SET_LOT, lotSet)]);

  /* SAGA */
}
