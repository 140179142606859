import styled from "styled-components";

interface ScrollableProps {
    height?: string;
}

export const Scrollable = styled.div<ScrollableProps>`
    display: flex;
    flex: 1;
    align-items: stretch;
    width: 97%; /* Ширина контейнера составляет 80% от ширины родительского элемента */
    max-width: 1000px; /* Максимальная ширина контейнера */
    height: ${(props) => props.height || '75vh'}; /* Высота контейнера составляет 70% от высоты окна просмотра */
    overflow-y: auto; /* Включает вертикальный скролл, если содержимое превышает высоту контейнера */
    padding: 0px; /* Внутренние отступы */
    border: 0px; /* Граница контейнера */
    box-sizing: border-box; /* Убедитесь, что padding и border включены в общую ширину и высоту */

    /* Скрывает скроллбар в WebKit-браузерах */
    &::-webkit-scrollbar {
    display: none;
    }
    
    /* Скрывает скроллбар в Firefox */
    scrollbar-width: none;
    
    /* Скрывает скроллбар в IE и Edge */
    -ms-overflow-style: none;
`;
