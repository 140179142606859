// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAzz4aZwUQEo9T_TurjL9difuzWvc5TC6I",
    authDomain: "trading-lf09.firebaseapp.com",
    projectId: "trading-lf09",
    storageBucket: "trading-lf09.appspot.com",
    messagingSenderId: "763025106210",
    appId: "1:763025106210:web:7bfca1c9f6e69c9b45717b"
};

export const vapidKey = 'BJmL1xz6IHlgkprb34ZjsS8Ved0qPj1r7dtczhGHBiAFyGntBQn5EESDSPkLgy0sFocAkksDdz8x7FhRcIv1QcY';

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
