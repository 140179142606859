import {BaseRequest} from './BaseRequest';
import {IAlertRequestDTO, IAlertResponseDTO, IDataAlert} from '../types';
import {endpoint} from "../helpers";

export class RemoveAlertRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = RemoveAlertRequest.URL) {
    super(baseUrl);
  }

  public query = async (req: IAlertRequestDTO, token: string): Promise<Partial<IDataAlert>> => {
    return this.fetchJSON(`v1/alert/remove`, 'POST', req, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: IAlertResponseDTO) => {
          return {
            stateData: {
              loading: false,
            },
          };
        });
  };
}

export const removeAlertRequest = new RemoveAlertRequest();
