import {EActions} from '../../actions';
import {IAction} from '../../types';
import {IDataSearch, IStateSearch} from './types';

export const initial: IStateSearch = {
  search: {
    text: undefined,
  },
};

export function searchReducer(state = initial, action: IAction<IDataSearch>): IStateSearch {
  switch (action.type) {
      /* REDUCER_CASE_ACTION */
    case EActions.SEARCH:
      return {
        search: {
          text: action.payload?.text,
        },
      };
    default:
      return state;
  }
}
