export class BaseRequest {
  constructor(protected baseUrl: string | undefined = '') {}

  async fetch(url: string, method: Methods = 'GET', body?: object, headers?: { [key: string]: string }): Promise<any> {
    const fullUrl = `${this.baseUrl}/${url}`;
    // console.log('test.fetch', fullUrl);
    const options: RequestInit = {
      method,
      mode: "cors", // no-cors, *cors, same-origin
      // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers,
      body: body ? JSON.stringify(body) : undefined, // body data type must match "Content-Type" header
    };

    return fetch(fullUrl, options)
      .then(response => {
          try {
              return response.json();
          } catch (e) {
              if (response.status < 200 || response.status  >= 300) {
                  throw new Error(`${response.status} ${response.statusText}` );
              }
          }
        return response;
      });
  }

  async fetchJSON(url: string, method: Methods = 'GET', body?: object, headers?: { [key: string]: string }): Promise<any> {
    return this.fetch(url, method, body, { ...headers, 'Content-Type': 'application/json' })
        .then(r => {
            if (!r.status) {
                throw Error(r.message);
            }
            return r;
        })
        .catch(e => {
            console.log('test.BaseRequest', e);
            throw e;
        });
  }
}

type Methods = 'POST' | 'GET' | 'DELETE' | 'PUT' | 'post' | 'get' | 'delete' | 'put';
