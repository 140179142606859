import {BaseRequest} from './BaseRequest';
import {IDataSymbols, ISymbolsResponseDTO} from '../types';
import {endpoint} from "../helpers";

export class SymbolsRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = SymbolsRequest.URL) {
    super(baseUrl);
  }

  public query = async (token: string, id_user: number): Promise<Partial<IDataSymbols>> => {
    return this.fetchJSON(`v1/bybit/symbols/${id_user}`, 'GET', undefined, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: ISymbolsResponseDTO) => {
          return {
            list: r.data,
            stateData: {
              loading: false,
            },
          };
        });
  };
}

export const symbolsRequest = new SymbolsRequest();
