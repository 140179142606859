import {EActions} from '../../actions';
import {IAction, ISymbolID} from '../../types';
import {IDataLot, IStateLot} from './types';

export const initial: IStateLot = {
  lots: {
    lot: undefined,
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function lotReducer(state = initial, action: IAction<Partial<IDataLot> | ISymbolID>): IStateLot {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.NEW_LOT:
      return {
        lots: {
          lot: { id: 0, name: '', ids: [] },
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.ADD_TO_LOT:
      return {
        lots: {
          lot: { ...state.lots.lot!, ids: [ ...state.lots.lot!.ids!, (action.payload as ISymbolID).id_symbol ] },
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REMOVE_FROM_LOT:
      return {
        lots: {
          lot: { ...state.lots.lot!, ids: [ ...state.lots.lot!.ids!.filter(id => id !== (action.payload as ISymbolID).id_symbol) ] },
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.SET_LOT:
      return {
        lots: {
          lot: (action.payload as Partial<IDataLot>).lot,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.SET_LOT_OK:
      return {
        lots: {
          lot: (action.payload as Partial<IDataLot>).lot,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.SET_LOT_ERR:
      return {
        lots: {
          ...state.lots,
          stateData: {
            loading: false,
            error: (action.payload as Partial<IDataLot>).stateData?.error,
          },
        },
      };
    case EActions.REQUEST_LOT:
      return {
        lots: {
          lot: (action.payload as Partial<IDataLot>).lot,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_LOT_OK:
      return {
        lots: {
          lot: (action.payload as Partial<IDataLot>).lot,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_LOT_ERR:
      return {
        lots: {
          ...state.lots,
          stateData: {
            loading: false,
            error: (action.payload as Partial<IDataLot>).stateData?.error,
          },
        },
      };
    default:
      return state;
  }
}
