import { initial } from './reducer';
import {createSelector} from "reselect";
import {IState} from "../reducers";

const selectTabIndex = (state: IState): number => {
  const { navigation } = state;
  return navigation.navigation.tabIndex ? navigation.navigation.tabIndex : initial.navigation.tabIndex;
};

const selectParams = (state: IState): object => {
  const { navigation } = state;
  return navigation.navigation.params;
};

export const makeSelectTabIndex = () =>
    createSelector(selectTabIndex, substate => substate);

/* SELECTOR */

export {
  selectTabIndex,
  selectParams,
  /* SELECTOR_EXPORT */
};
