import {call, put, select, race, take} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {lotSetRequest, lotRequest} from '../request';
import {IAction, IDataLot, ILotRequestDTO, TAsyncQuery2} from "../types";
import {selectAuth, selectTokens} from '../store/selectors';
import {checkHttpError} from '../helpers';

/* SAGA */

export function* lotSet(_action: IAction<IDataLot>): SagaIterator {
    try {
        const isNew = _action.payload?.lot?.id === 0;

        const { accessToken } = yield select(selectTokens);
        const payload = yield call<TAsyncQuery2<ILotRequestDTO, string, Partial<IDataLot>>>(lotSetRequest.query, _action.payload!.lot!, accessToken);
        yield put(action(EActions.SET_LOT_OK, payload));

        yield put(action(EActions.REQUEST_SYMBOLS));
        const { error } = yield race({
            error: take(EActions.REQUEST_SYMBOLS_ERR),
            success: take(EActions.REQUEST_SYMBOLS_OK),
        });
        if (isNew) {
            if (!error) {
                const {id} = yield select(selectAuth);
                yield put(action(EActions.SET_FAVORITE, {id_user: id, id_symbol: payload.lot.id}));
            }
        }
    } catch (error) {
        yield put(action(EActions.SET_LOT_ERR, { stateData: { error: checkHttpError(error) } }));
    }
}

export function* lot(_action: IAction<IDataLot>): SagaIterator {
    try {
        const { accessToken } = yield select(selectTokens);
        const payload = yield call<TAsyncQuery2<ILotRequestDTO, string, Partial<IDataLot>>>(lotRequest.query, _action.payload!.lot!, accessToken);
        yield put(action(EActions.REQUEST_LOT_OK, payload));
    } catch (error) {
        yield put(action(EActions.REQUEST_LOT_ERR, { stateData: { error: checkHttpError(error) } }));
    }
}

