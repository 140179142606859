import {EActions} from '../../actions';
import {IAction} from '../../types';
import {IDataAlert, IStateAlert} from './types';

export const initial: IStateAlert = {
  alert: {
    id: undefined,
    id_user: undefined,
    id_symbol: undefined,
    val: undefined,
    condition: undefined,
    symbol: undefined,
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function alertReducer(state = initial, action: IAction<Partial<IDataAlert>>): IStateAlert {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.BEFORE_NEW_ALERT:
      return {
        alert: {
          ...action.payload,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.NEW_ALERT:
      return {
        alert: {
          ...action.payload,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REMOVE_ALERT:
    case EActions.CREATE_ALERT:
      return {
        alert: {
          ...action.payload,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.CREATE_ALERT_OK:
      return {
        alert: {
          ...state.alert,
          ...action.payload,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };

    case EActions.REMOVE_ALERT_OK:
      return {
        alert: {
          ...state.alert,
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REMOVE_ALERT_ERR:
    case EActions.CREATE_ALERT_ERR:
      return {
        alert: {
          ...state.alert,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
    default:
      return state;
  }
}
