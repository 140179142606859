import React, {useCallback} from 'react'
import {Header, List, ListContent, ListIcon, ListItem, Loader, Message} from 'semantic-ui-react';
import {createStructuredSelector} from "reselect";
import {Dispatch} from "redux";
import {action, EActions} from "../../actions";
import {connect, ConnectedProps} from "react-redux";
import {IDataAlert, IDataLot, ISymbolDTO} from "../../types";
import {makeSelectAuth} from "../../store/auth";
import {useNavigate} from "react-router-dom";
import {makeSelectSymbols} from "../../store/symbols";
import {makeSelectSearch} from "../../store/search";
import {Scrollable} from "./Scrollable";

interface ISymbolsProps extends TSymbolsProps {
}

const Symbols = (props: ISymbolsProps) => {
    const navigate = useNavigate();

    const refresh = useCallback((dto: ISymbolDTO) => {
        props.refresh(dto.id);
    }, [props.refresh]);

    const edit = useCallback((dto: ISymbolDTO) => {
        props.editLot({ lot: { id: dto.id } });
        navigate('/lot');
    }, [props.editLot]);

    const beforeCreateAlert = useCallback((dto: ISymbolDTO) => {
        props.beforeNewAlert({
            symbol: dto,
            id_user: props.auth.id,
            id_symbol: dto.id,
            val: dto.lastPrice,
        });
        navigate('/alert');
    }, [navigate, props.auth, props.beforeNewAlert]);

    const favorite = useCallback((dto: ISymbolDTO) => {
        if (dto.id_user) {
            props.unsetFavorite(props.auth.id!, dto.id);
        } else {
            props.setFavorite(props.auth.id!, dto.id);
        }
    }, [props.auth, props.setFavorite, props.unsetFavorite]);

    if (props.symbols.stateData.loading) {
        return <Loader inverted>Loading</Loader>;
    }

    if (!props.symbols.list || props.symbols.list.length === 0) {
        return (
            <Message>
                <p>Пар нет.</p>
            </Message>
        );
    }

    return (
        //   style={{ overflow: 'auto', maxHeight: '65vh'}}
        <Scrollable>
            <List selection divided verticalAlign={'middle'}>
                {props.symbols.list.filter(i => props.search.text ? i.name.includes(props.search.text) : true).map(i => (
                    <ListItem key={i.id}>
                        <ListIcon verticalAlign={'middle'} name={i.id_user ? 'star' : 'star outline'} size={'large'} onClick={() => favorite(i)} />
                        <ListContent as='a' style={{ height:'3rem' }}>{`${i.name}`}</ListContent>
                        {(i.lastPrice > 0 || i.prevPrice > 0) && (
                            <ListIcon verticalAlign={'middle'} size={'large'}
                                name={i.lastPrice > i.prevPrice ? 'long arrow alternate up' : 'long arrow alternate down'}
                                color={i.lastPrice > i.prevPrice ? 'green' : 'red'}
                            />
                        )}
                        {i.lot === 0 && <ListIcon className={'pl2r'} verticalAlign={'middle'}><Header size='medium'>{i.lastPrice}</Header></ListIcon>}
                        {i.lot === 0 && <ListIcon className={'pl3r'} verticalAlign={'middle'} name={'refresh'} size={'large'} onClick={() => refresh(i)} />}
                        {i.lot === 1 && <ListIcon className={'pl3r'} verticalAlign={'middle'} name={'pencil'} size={'large'} onClick={() => edit(i)} />}
                        <ListIcon className={'pl2r'} verticalAlign={'middle'} name={'bell outline'} size={'large'} onClick={() => beforeCreateAlert(i)} />
                    </ListItem>
                ))}
            </List>
        </Scrollable>
    );
}

const mapStateToProps = createStructuredSelector({
    auth: makeSelectAuth(),
    symbols: makeSelectSymbols(),
    search: makeSelectSearch(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        refresh: (id_symbol: number) => dispatch(action(EActions.REQUEST_LAST_PRICE, { id_symbol })),
        beforeNewAlert: (payload: Partial<IDataAlert>) => dispatch(action(EActions.BEFORE_NEW_ALERT, payload)),
        setFavorite: (id_user: number, id_symbol: number) => dispatch(action(EActions.SET_FAVORITE, { id_user, id_symbol })),
        unsetFavorite: (id_user: number, id_symbol: number) => dispatch(action(EActions.UNSET_FAVORITE, { id_user, id_symbol })),
        editLot: (payload: Partial<IDataLot>) => dispatch(action(EActions.REQUEST_LOT, payload)),
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type TSymbolsProps = ConnectedProps<typeof connector>

export default connector(Symbols);
