import { BaseRequest } from './BaseRequest';
import { IDataToken, ITokenRequestDTO, ITokenResponseDTO} from '../types';
import {endpoint} from "../helpers";

export class TokenRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = TokenRequest.URL) {
    super(baseUrl);
  }

  public query = async (req: ITokenRequestDTO): Promise<Partial<IDataToken>> => {
    return this.fetchJSON('v1/token', 'POST', req)
        .then((r: ITokenResponseDTO) => {
          return {
            accessToken: r.data?.accessToken,
            refreshToken: r.data?.refreshToken,
            stateData: {
              loading: false,
            },
          };
        });
  };
}

export const tokenRequest = new TokenRequest();
