import {BaseRequest} from './BaseRequest';
import {IAlertRequestDTO, IAlertResponseDTO, IDataAlert} from '../types';
import {endpoint} from "../helpers";

export class CreateAlertRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = CreateAlertRequest.URL) {
    super(baseUrl);
  }

  public query = async (req: IAlertRequestDTO, token: string): Promise<Partial<IDataAlert>> => {
    return this.fetchJSON(`v1/alert/add`, 'POST', req, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: IAlertResponseDTO) => {
          return {
            id: r.data?.id,
            stateData: {
                loading: false,
            },
          };
        });
  };
}

export const createAlertRequest = new CreateAlertRequest();
