import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {authRequest} from '../request';
import {IAction, IAuthRequestDTO, IDataAuth, TAsyncQuery2} from "../types";
import { selectTokens } from '../store/selectors';
import { checkHttpError } from '../helpers';

/* SAGA */

export function* login(_action: IAction<IDataAuth>): SagaIterator {
  try {
    const req: IAuthRequestDTO = {
      publicKey: '',
      login: '@' + _action.payload?.login ?? '',
      secret: _action.payload?.password ?? '',
    };
    const tokens = yield select(selectTokens);
    const payload = yield call<TAsyncQuery2<IAuthRequestDTO, string, Partial<IDataAuth>>>(authRequest.query, req, tokens.accessToken);
    yield put(action(EActions.REQUEST_LOGIN_OK, payload));
    yield put(action(EActions.REQUEST_PUSH_TOKEN));
  } catch (error) {
    yield put(action(EActions.REQUEST_LOGIN_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
