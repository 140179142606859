import {BaseRequest} from './BaseRequest';
import {endpoint} from "../helpers";

export class UnsetFavoriteRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = UnsetFavoriteRequest.URL) {
    super(baseUrl);
  }

  public query = async (token: string, id_user: number, id_symbol: number): Promise<boolean> => {
    return this.fetchJSON(`v1/bybit/favorite/unset/${id_user}/${id_symbol}`, 'GET', undefined, {
      'Authorization': `Bearer ${token}`,
    })
        .then(() => true);
  };
}

export const unsetFavoriteRequest = new UnsetFavoriteRequest();
