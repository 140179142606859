import {call, put} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {pushTokenRequest} from '../request';
import {IAction, IDataPushToken, TAsyncQuery,} from "../types";
import {checkHttpError} from "../helpers";

/* SAGA */

export function* pushToken(_action: IAction<IDataPushToken>): SagaIterator {
    try {
        const payload = yield call<TAsyncQuery<Partial<IDataPushToken>>>(pushTokenRequest.query);
        yield put(action(EActions.REQUEST_PUSH_TOKEN_OK, payload));
    } catch (error: any) {
        // console.log('test.pushToken', error);
        yield put(action(EActions.REQUEST_PUSH_TOKEN_ERR, { stateData: { error: checkHttpError(error) } }));
    }
}
