import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {lastPriceRequest} from '../request';
import {IAction, IDataLastPrice, TAsyncQuery2} from "../types";
import {selectTokens} from '../store/selectors';
import {checkHttpError} from '../helpers';

/* SAGA */

export function* lastPrice(_action: IAction<IDataLastPrice>): SagaIterator {
  try {
    const tokens = yield select(selectTokens);
    const payload = yield call<TAsyncQuery2<string, number, Partial<IDataLastPrice>>>(lastPriceRequest.query, tokens.accessToken, _action.payload!.id_symbol);
    yield put(action(EActions.REQUEST_LAST_PRICE_OK, payload));
  } catch (error) {
    yield put(action(EActions.REQUEST_LAST_PRICE_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
