import {createSelector} from "reselect";
import {IState} from "../reducers";
import {EDDeviceFormFactor} from "../../types";
import {formFactor} from "../../helpers";

const selectFormFactor = (state: IState): EDDeviceFormFactor => {
  return formFactor;
};

export const makeSelectFormFactor = () =>
    createSelector(selectFormFactor, substate => substate);

/* SELECTOR */

export {
  selectFormFactor,
  /* SELECTOR_EXPORT */
};
