import {BaseRequest} from './BaseRequest';
import {IDataLastPrice, ILastPriceResponseDTO} from '../types';
import {endpoint} from "../helpers";

export class LastPriceRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = LastPriceRequest.URL) {
    super(baseUrl);
  }

  public query = async (token: string, id_symbol: number): Promise<Partial<IDataLastPrice>> => {
    return this.fetchJSON(`v1/bybit/symbol/lastprice/${id_symbol}`, 'GET', undefined, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: ILastPriceResponseDTO) => {
          return {
            symbol: r.data
          };
        });
  };
}

export const lastPriceRequest = new LastPriceRequest();
