
export function href() {
    return window.location.href
}

export const DEBUG = href().includes('localhost');

export function endpoint() {
    if (DEBUG) return 'http://localhost:5005';

    return 'https://brungade.leafrow.com';
}
