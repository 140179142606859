import {BaseRequest} from './BaseRequest';
import {ILotRequestDTO, ILotResponseDTO, IDataLot} from '../types';
import {endpoint} from "../helpers";

export class LotSetRequest extends BaseRequest {
  static URL = endpoint();

  constructor(protected baseUrl: string | undefined = LotSetRequest.URL) {
    super(baseUrl);
  }

  public query = async (req: ILotRequestDTO, token: string): Promise<Partial<IDataLot>> => {
    return this.fetchJSON(`v1/lot/set`, 'POST', req, {
      'Authorization': `Bearer ${token}`,
    })
        .then((r: ILotResponseDTO) => {
            console.log('test.LotSetRequest', {
                r,
            });
          return {
            lot: r.data,
            stateData: {
                loading: false,
            },
          };
        });
  };
}

export const lotSetRequest = new LotSetRequest();
