import {EActions} from '../../actions';
import {IAction} from '../../types';
import {IDataSymbolInfo, IStateSymbolInfo} from './types';

export const initial: IStateSymbolInfo = {
  info: {
    id_symbol: 0,
    interval: 0,
    info: {},
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function infoReducer(state = initial, action: IAction<Partial<IDataSymbolInfo>>): IStateSymbolInfo {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.REQUEST_RSI:
      return {
        info: {
          ...state.info,
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_RSI_OK:
      return {
        info: {
          id_symbol: 0,
          interval: 0,
          info: { ...state.info.info, ...action.payload?.info },
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_RSI_ERR:
      return {
        info: {
          ...state.info,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
    default:
        return state;
  }
}
