import {EActions} from '../../actions';
import {IAction, IDataFavorite, IDataLastPrice} from '../../types';
import {IDataSymbols, IStateSymbols} from './types';

export const initial: IStateSymbols = {
  symbols: {
    list: [],
    stateData: {
      loading: false,
      error: undefined,
    },
  },
};

export function symbolsReducer(state = initial, action: IAction<Partial<IDataSymbols & IDataFavorite & IDataLastPrice>>): IStateSymbols {
  switch (action.type) {
    /* REDUCER_CASE_ACTION */
    case EActions.REQUEST_SYMBOLS:
      return {
        symbols: {
          stateData: {
            loading: true,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_SYMBOLS_OK:
      return {
        symbols: {
          list: [ ...(action.payload?.list || []) ],
          stateData: {
            loading: false,
            error: undefined,
          },
        },
      };
    case EActions.REQUEST_LAST_PRICE_OK:
        const symbol = action.payload?.symbol;
        const list = state.symbols.list?.map(i => {
            if (i.id === symbol?.id) {
                return { ...i, ...symbol };
            }
            return i;
        });
        return {
            symbols: {
                list: [ ...(list ?? []) ],
                stateData: {
                    loading: false,
                    error: undefined,
                },
            },
        };
    case EActions.REQUEST_LAST_PRICE_ERR:
    case EActions.REQUEST_SYMBOLS_ERR:
      return {
        symbols: {
          ...state.symbols,
          stateData: {
            loading: false,
            error: action.payload?.stateData?.error,
          },
        },
      };
      case EActions.SET_FAVORITE_OK: {
          const favorite = state.symbols.list?.filter(i => i.id === action.payload?.id_symbol)
              .map(i => {
                  i.id_user = action.payload?.id_user;
                  return i;
              }) ?? [];
          const favorites = state.symbols.list?.filter(i => !!i.id_user && i.id !== favorite?.[0].id) ?? [];
          const others = state.symbols.list?.filter(i => !i.id_user && i.id !== favorite?.[0].id) ?? [];
          return {
              symbols: {
                  ...state.symbols,
                  list: [...favorite, ...favorites, ...others],
              },
          };
      }
      case EActions.UNSET_FAVORITE_OK: {
          const favorite = state.symbols.list?.filter(i => i.id === action.payload?.id_symbol)
              .map(i => {
                  i.id_user = undefined;
                  return i;
              }) ?? [];
          const favorites = state.symbols.list?.filter(i => !!i.id_user && i.id !== favorite?.[0].id) ?? [];
          const others = state.symbols.list?.filter(i => !i.id_user && i.id !== favorite?.[0].id) ?? [];
          return {
              symbols: {
                  ...state.symbols,
                  list: [...favorites, ...favorite, ...others],
              },
          };
      }

      default:
        return state;
  }
}
