
// Отзеркалить в getPeriodName() -> Sender.go
// в value милисекундах как в Tickers
export class MetaAlert {
    static second = 1000;
    static minute = MetaAlert.second * 60;
    static hour = MetaAlert.minute * 60;
    static day = MetaAlert.hour * 24;
    static week = MetaAlert.day * 7;
    static month = MetaAlert.day * 30;

    static conditions = [
        { key: '1', text: 'По достижению цены', value: 1 },
        { key: '2', text: 'По коэффициенту роста', value: 2 },
        { key: '3', text: 'По коэффициенту падения', value: 3 },
        { key: '4', text: 'По росту RSI', value: 4 },
        { key: '5', text: 'По падению RSI', value: 5 },
    ]

    static intervals = [
        { key: '1', text: 'Минута', value: MetaAlert.minute },
        { key: '2', text: '5 минут', value: MetaAlert.minute * 5 },
        { key: '3', text: '10 минут', value: MetaAlert.minute * 10 },
        { key: '4', text: '30 минут', value: MetaAlert.minute * 30 },
        { key: '5', text: '1 час', value: MetaAlert.hour },
        { key: '6', text: '2 часа', value: MetaAlert.hour * 2 },
        { key: '7', text: '3 часа', value: MetaAlert.hour * 3 },
        { key: '8', text: '6 часов', value: MetaAlert.hour * 6 },
        { key: '9', text: '12 часов', value: MetaAlert.hour * 12 },
        { key: '10', text: '1 день', value: MetaAlert.day },
        { key: '11', text: '2 дня', value: MetaAlert.day * 2 },
        { key: '12', text: '3 дня', value: MetaAlert.day * 3 },
        { key: '13', text: '4 дня', value: MetaAlert.day * 4 },
        { key: '14', text: '5 дней', value: MetaAlert.day * 5 },
        { key: '15', text: '6 дней', value: MetaAlert.day * 6 },
        { key: '16', text: '1 неделю', value: MetaAlert.week },
        { key: '17', text: '2 недели', value: MetaAlert.week * 2 },
        { key: '18', text: '1 месяц', value: MetaAlert.month },
    ]

    /*
    Общий временной интервал для RSI
    * */
    static periods = [
        { key: '1', text: '7 Минут', value: MetaAlert.minute * 7},
        { key: '2', text: '14 Минут', value: MetaAlert.minute * 14},
        { key: '3', text: '20 Минут', value: MetaAlert.minute * 20},
        { key: '4', text: '7 часов', value: MetaAlert.hour * 7 },
        { key: '5', text: '14 часов', value: MetaAlert.hour * 14 },
        { key: '6', text: '20 часов', value: MetaAlert.hour * 20 },
        { key: '7', text: 'Неделя', value: MetaAlert.day * 7 },
        { key: '8', text: '2 недели', value: MetaAlert.day * 14 },
        { key: '9', text: '20 дней', value: MetaAlert.day * 20 },
    ]

    static isNumeric(s: string) {
        return !isNaN(parseFloat(s)) && isFinite(Number(s));
    }

    static validateNumerics(values: (string | undefined)[]): boolean {
        return values.reduce((acc, i) => (!i || !MetaAlert.isNumeric(i)) ? false : acc, true);
    }

    static validateRSI(value: (string | undefined)): boolean {
        let valid = MetaAlert.validateNumerics([value]);
        if (valid) {
            const num = Number(value);
            valid = num > 0 && num < 100;
        }
        return valid;
    }

    static getPeriodName(interval: number): string {
        switch (interval) {
        case MetaAlert.minute:
            return "1 минуту"
        case MetaAlert.minute * 5:
            return "5 минут"
        case MetaAlert.minute * 7:
            return "7 минут"
        case MetaAlert.minute * 10:
            return "10 минут"
        case MetaAlert.minute * 14:
            return "14 минут"
        case MetaAlert.minute * 20:
            return "20 минут"
        case MetaAlert.minute * 30:
            return "30 минут"
        case MetaAlert.hour:
            return "1 час"
        case MetaAlert.hour * 2:
            return "2 часа"
        case MetaAlert.hour * 3:
            return "3 часа"
        case MetaAlert.hour * 6:
            return "6 часов"
        case MetaAlert.hour * 7:
            return "7 часов"
        case MetaAlert.hour * 12:
            return "12 часов"
        case MetaAlert.hour * 14:
            return "14 часов"
        case MetaAlert.hour * 20:
            return "20 часов"
        case MetaAlert.day:
            return "1 день"
        case MetaAlert.day * 2:
            return "2 дня"
        case MetaAlert.day * 3:
            return "3 дня"
        case MetaAlert.day * 4:
            return "4 дня"
        case MetaAlert.day * 5:
            return "5 дней"
        case MetaAlert.day * 6:
            return "6 дней"
        case MetaAlert.week:
            return "1 неделю"
        case MetaAlert.week * 2:
            return "2 недели"
        case MetaAlert.day * 20:
            return "20 дней"
        case MetaAlert.month:
            return "1 месяц"
        default:
            return `${Math.round(100 * interval/MetaAlert.minute) / 100} минут`;
        }
    }
}
