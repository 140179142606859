import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Label, Segment} from 'semantic-ui-react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {Dispatch} from 'redux';
import {makeSelectAuth, makeSelectFormFactor} from "../../store/selectors";
import {action, EActions} from "../../actions";
import {IDataAuth, IDataState} from "../../types";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";

const sha512 = require('js-sha512').sha512;

const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
`;

interface ILoginProps extends TLoginProps {
}

function Login(props: ILoginProps) {

    const [userName, setUserName] = useState('');
    const [secret, setSecret] = useState('');

    const onChangeUserName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setUserName(data.value);
        },
        [userName],
    );

    const onChangeSecret = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            setSecret(data.value);
        },
        [secret],
    );

    const onSubmit = useCallback(
        () => {
            props.login({ login: userName, password: sha512(secret) });
            // console.log(sha512(secret));
        },
        [userName, secret],
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (!!props.auth.id) {
            navigate('/');
        }
    }, [props.auth.id]);

    const lock = userName.length === 0 && secret.length === 0;

    return (
        <Div>
            <Header as='h3' dividing>
                {'Brungade Trading'}
            </Header>
            <Segment placeholder>
                <Grid relaxed="very" stackable>
                    <Grid.Column>
                        <Form onSubmit={onSubmit}>
                            <Form.Input
                                icon="user"
                                iconPosition="left"
                                label="Username"
                                placeholder="User name"
                                onChange={onChangeUserName}
                                value={userName}
                            />
                            <Form.Input
                                icon="lock"
                                iconPosition="left"
                                label="Password"
                                type="password"
                                onChange={onChangeSecret}
                                value={secret}
                            />
                            {renderError(props.auth.stateData)}
                            <Button
                                type="submit"
                                loading={props.auth.stateData.loading}
                                disabled={lock}
                                content="Login"
                                primary
                                style={{ width: '100%' }}
                            />
                        </Form>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Div>
    );
}

function renderError(props: IDataState) {
    const { error } = props;
    if (error) {
        return (
            <Label basic color="red" pointing="below" style={{ width: '100%' }}>
                {error}
            </Label>
        );
    }
    return null;
}

const mapStateToProps = createStructuredSelector({
    auth: makeSelectAuth(),
    formFactor: makeSelectFormFactor(),
});

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        login: (payload: Partial<IDataAuth>) => dispatch(action(EActions.REQUEST_LOGIN, payload)),
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type TLoginProps = ConnectedProps<typeof connector>

export default connector(Login);
