import {call, put, select} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {action, EActions} from '../actions';
import {symbolsRequest} from '../request';
import {IAction, IDataAuth, IDataSymbols, TAsyncQuery2} from "../types";
import {selectAuth, selectTokens} from '../store/selectors';
import {checkHttpError} from '../helpers';

/* SAGA */

export function* symbols(_action: IAction<IDataAuth>): SagaIterator {
  try {
    const { id } = yield select(selectAuth);
    const tokens = yield select(selectTokens);
    const payload = yield call<TAsyncQuery2<string, number, Partial<IDataSymbols>>>(symbolsRequest.query, tokens.accessToken, id);
    yield put(action(EActions.REQUEST_SYMBOLS_OK, payload));
  } catch (error) {
    yield put(action(EActions.REQUEST_SYMBOLS_ERR, { stateData: { error: checkHttpError(error) } }));
  }
}
